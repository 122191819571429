import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Card, Spin } from "antd";
// import Spin from "./Spin"; // Assuming Spin is a custom loading spinner

const PaymentForm = ({ onNext, subscriptionId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const param = {
    package: "adil calling",
    package_id: query.get("package"),
    game_id: query.get("game"),
    subscriptionId: subscriptionId,
  };

  const handlePayment = async () => {
    if (isLoading) return; // Prevent duplicate requests
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post("/tappay/charge", param);
      const transaction = response.data.transaction;
      console.log("Response::", response.data);
      console.log("Transaction URL::", transaction.url);

      // Open the transaction URL in a new tab
      window.open(transaction.url, "_blank");
    } catch (err) {
      console.error("Payment API Error:", err.message);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-4 py-10 shadow shadow-purple-400">
      <h1 className="text-xl text-center text-white capitalize">
        {t("checkout.choose_payment_option")}
      </h1>
      <div className="mt-10 text-center">
        <button
          onClick={handlePayment}
          disabled={isLoading}
          className="relative w-full px-4 py-4 text-black transition duration-300 ease-in-out bg-white rounded-sm hover:bg-light-20"
        >
          {isLoading ? (
            <Spin size="small" />
          ) : (
            <span className="py-4 text-2xl font-medium">
              {t("checkout.pay")}
            </span>
          )}
        </button>
        {error && (
          <p className="mt-4 text-red-500">
            {t("checkout.payment_failed")}: {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default PaymentForm;
